<template>
  <div class="service">
    <div class="detail">
      <div class="panel">
        <div class="title">Our Company</div>
        <div class="content">
          Headquartered in Singapore, J&F Mobility is committed to the Smart
          Transportation Industry. It is committed to deliver smart road-side
          system to improve urban safety, traffic safety and transportation
          efficiency. J&F has strategic operating partners in Indonesia,
          Malaysia and China.
        </div>
        <div class="content">
          The company adopts “Developing business thru Engineering "strategy,
          and it positions high-end industry, based on independent innovation,
          specializing in intelligent transportation, public safety, smart city
          industry overall solution, core technology and products research.
        </div>
      </div>
      <div class="team-btn mybutton" @click="toRouter('/about-team')">
        <span>Our Team</span><img :src="require('/src/assets/img/right-arrow.png')" />
      </div>
    </div>
    <div class="panel points">
      <span class="row"
        ><span class="point"></span> Smart Transportation Solutions</span
      >
      <span class="row"
        ><span class="point"></span> Public Safety Solutions
      </span>
      <span class="row"
        ><span class="point"></span> Green Energy and Environmental
        Protection</span
      >
      <span class="row"
        ><span class="point"></span> Autonomous Driving Solutions</span
      >
      <span class="row"
        ><span class="point"></span> Vehicle-Road-Infra Collaborative
        Solutions</span
      >
      <span class="row"><span class="point"></span> Smart City Solutions</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/about_us.png") center center;
  background-size: cover;
  .panel {
    background: #154d7865;
  }
  .detail {
    margin: 3vh 0px 6vh 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    > .panel {
      padding: 0px 1.3vw;
    }
    .team-btn {
      margin: 0px 2vw;
      font-size: 4.17vh;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 5.83vh;
      letter-spacing: 0.146vw;
      text-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.5);
      white-space: nowrap;
      display: flex;
      align-items: center;
      >img{
        height: 5.4vh;
      }
    }
    .title {
      font-size: 4.17vh;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 5.83vh;
      letter-spacing: 0.146vw;
    }
    .content {
      font-size: 3.33vh;
      font-family: Helvetica, Helvetica-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 4.03vh;
      letter-spacing:0.117vw;
      margin-bottom: 5vh;
    }
  }
  .points {
    width: 85%;
    float: right;
    .row {
      display: inline-block;
      &:nth-child(odd) {
        width: 60%;
      }
      &:nth-child(even) {
        width: 40%;
      }

      font-size: 3.33vh;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 4.65vh;
      letter-spacing:0.117vw;
    }

    .point {
      display: inline-block;
      margin: 0px 24px;
      width: 2.5vh;
      height: 25.5px;
      background: #f07171;
      border: 0.75px solid #979797;
      border-radius: 6px;
      border: 1.5px solid #ffffff;
    }
  }
}
</style>
